<template>
  <div>
    <div>
      <!-- table -->
      <data-table
        :columns="columns"
        requesturl="/analytics"
        :server-params-modifier="serverParamsModifier"
        @on-row-click="showAnalyticsHistory()"
      >
        <template
          #actions="{ rowData }"
        >
          <!-- Column: Action -->
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                @click="selectedBarcodeHistory = rowData.status_history; $refs.analyticsDataHistory.show()"
              >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Show History</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </template>
      </data-table>
      <b-modal
        ref="analyticsDataHistory"
        title="History"
      ><div
        v-for="(data,index) in selectedBarcodeHistory"
        :key="index"
      >
        <h5 class="text-primary">
          Status: {{ data.status }} on {{ data.timestamp | formatTimestamp }}
        </h5>
        <h6
          v-if="data.comment"
          class="mb-2 text-success"
        >
          Comment: {{ data.comment }}
        </h6>
      </div>
      </b-modal>

    </div>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable.vue'
import { formatDateToMonthShort } from '@/@core/utils/filter'

export default {
  components: {
    DataTable,
  },
  filters: {
    formatTimestamp(date) {
      return formatDateToMonthShort(date)
    },
  },
  data() {
    return {
      selectedBarcodeHistory: null,
      pageLength: 20,
      columns: [
        {
          label: 'Sample ID',
          field: 'sample_id',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Date',
          field: 'timestamp',
          formatFn: value => formatDateToMonthShort(value),
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Comment',
          field: 'comment',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
    }
  },
  methods: {
    serverParamsModifier(serverParams) {
      const serverParamsClone = JSON.parse(JSON.stringify(serverParams))
      return serverParamsClone
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
